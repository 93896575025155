import { AnimatePresence, motion } from "framer-motion";

const transition = {
  duration: 4,
  ease: [0.43, 0.13, 0.23, 0.96]
}

const textTransition = {
  duration: 0.6,
  ease: [0.43, 0.13, 0.23, 0.96],
  delay: 1
}

export default function ProjectsCarouselItem(props) {

  return (
    <AnimatePresence exitBeforeEnter>
      {props.is_active &&
        <motion.li
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0, transition: {...transition, duration: 8 } }}
          transition={transition}
          className="projects_carousel__list__item"
        >
          <img
            className="projects_carousel__list__item__image"
            src={props.image.url}
            alt={props.image.alt}
            width=""
            height=""
          />
          <motion.h3
            initial={{ opacity: 0/*, y: '-100px'*/ }}
            animate={{ opacity: 1/*, y: 0*/ }}
            exit={{ opacity: 0/*, x: '-100px'*/, transition: {...textTransition, delay: 0 } }}
            transition={textTransition}
            className="projects_carousel__list__item__title"
          >{props.name}</motion.h3>
        </motion.li>
      }
    </AnimatePresence>
  );
}
