import { motion } from "framer-motion";

import './ProjectsIntro.scss';

export default function ProjectsIntro(props) {

  return (
    <div className="projects_intro">
      <div className="projects_intro__content" >
        <motion.h1
          initial={{ x: '-100%', opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ opacity: 0, transition: { delay: 0 } }}
          transition={{ duration: 0.6, delay: 0.6 }}
          className="projects_intro__content__heading"
        >
          {props.title}
        </motion.h1>
        <motion.div
          initial={{ y: '100%', opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ opacity: 0, transition: { delay: 0 } }}
          transition={{ duration: 0.6, delay: 1.2 }}
        >
          <p className="projects_intro__content__introduction" dangerouslySetInnerHTML={{ __html: props.intro }}></p>
          <h2 className="projects_intro__content__instruction">Scroll down to explore our work</h2>
          <h2 className="projects_intro__content__instruction_mobile">Swipe down to explore our work</h2>
        </motion.div>
      </div>
      <motion.img
        initial={{ x: '100%', opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ opacity: 0, transition: { delay: 0 } }}
        transition={{ duration: 0.6 }}
        className="projects_intro__image"
        src={props.image}
        alt=""
      />
      <h2 className="projects_intro__instruction">Swipe down to explore our work</h2>
    </div>
  );
}
