import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { AnimatePresence, motion } from "framer-motion";

import Header from '../components/HeaderComponent/Header';
import ProjectsCarousel from '../components/ProjectsCarouselComponent/ProjectsCarousel';
import HomeMenu from '../components/HomeMenuComponent/HomeMenu';
import Newsletter from '../components/NewsletterComponent/Newsletter';

const containerStyle = {
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  backgroundColor: '#2d3434'
}

function HomeView() {
  const [dataState, setDataState] = useState({
    loading: true,
    data: [],
  });

  const slug = 'home';
  const [page, setPage] = useState(null);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_REST_API_URL}/page/${slug}`, {
        method: 'GET',
        mode: 'cors'
    }).then(response => response.json())
        .then(page => setPage(page))
  }, []);

  useEffect(() => {
    const endpoint = `${process.env.REACT_APP_API_URL}/wp-admin/admin-post.php?action=get_projects&is_featured=true`;
    fetch(endpoint) 
      .then((res) => res.json())
      .then((data) => {
        setDataState({ loading: false, data: data });
      });
  }, [setDataState]);

  if (!page) return null;

  return (
    <div style={containerStyle}>
      <Helmet>
        {page.metadata.seo_title ? <title>{page.metadata.seo_title}</title> : null}
        {page.metadata.seo_description ? <meta name="description" content={page.metadata.seo_description} /> : null}
      </Helmet>
      <Header />
        <motion.div
          initial={{ opacity: 1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="home"
        >
          <AnimatePresence>
            {dataState.data.length &&
              <>
                <ProjectsCarousel projects={dataState.data} />
                <HomeMenu />
                <Newsletter isMinimalDisplay={true} />
              </>
            }
          </AnimatePresence>
        </motion.div>
    </div>
  );
}

export default HomeView;
