import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import Header from '../components/HeaderComponent/Header';
import Footer from '../components/FooterComponent/Footer';
import Product from '../components/ProductComponent/Product';

function ProductView() {
  const { slug } = useParams();

  const [dataState, setDataState] = useState({
    loading: true,
    data: {}
  });

  const pageSlug = 'product';
  const [page, setPage] = useState(null);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_REST_API_URL}/page/${pageSlug}`, {
        method: 'GET',
        mode: 'cors'
    }).then(response => response.json())
        .then(page => setPage(page))
  }, []);

  useEffect(() => {
    const endpoint = `${process.env.REACT_APP_API_URL}/wp-admin/admin-post.php?action=get_product&slug=${slug}`;
    fetch(endpoint)
      .then((res) => res.json())
      .then((data) => {
        setDataState({ loading: false, data: data });
      });
  }, [setDataState]);

  return (
    <>
      <div className="project_view">
        <Header />
        {!dataState.loading &&
          <Product
            title={dataState.data.name}
            intro={dataState.data.description}
            thumbnail={dataState.data.thumbnail}
            meta={dataState.data.specifications}
            shop_link={dataState.data.shop_link}
            gallery={dataState.data.gallery}
            next_product_slug={dataState.data.next_product_slug}
          />
        }
      </div>
      <Footer />
    </>
  );
}

export default ProductView;
