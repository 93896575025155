import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";

import Header from '../components/HeaderComponent/Header';
import Footer from '../components/FooterComponent/Footer';

function ContactView() {

  const slug = 'contact';

  const [state, setState] = useState({
    loading: true,
    data: {}
  });

  const pageSlug = 'contact';
  const [page, setPage] = useState(null);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_REST_API_URL}/page/${pageSlug}`, {
        method: 'GET',
        mode: 'cors'
    }).then(response => response.json())
        .then(page => setPage(page))
  }, []);

  useEffect(() => {
    const endpoint = `${process.env.REACT_APP_API_URL}/wp-admin/admin-post.php?action=get_page_content&slug=${slug}`;
    fetch(endpoint)
      .then((res) => res.json())
      .then((data) => {
        setState({ loading: false, data: data });
      });
  }, [setState]);

  if (!page) return null;

  return (
    <>
      <Helmet>
        {page.metadata.seo_title ? <title>{page.metadata.seo_title}</title> : null}
        {page.metadata.seo_description ? <meta name="description" content={page.metadata.seo_description} /> : null}
      </Helmet>
      <Header />
      <div className="page studio">
      {!state.loading &&
        <>
          <motion.h1
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="page__heading"
          >{state.data.name}</motion.h1>
          <motion.img
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="studio__image"
            src={state.data.thumbnail}
            alt=""
            width=""
            height=""
          />
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="studio__content"
          >
            <div className="studio__content__description">
              <h2>Studio:</h2>
              <p dangerouslySetInnerHTML={{ __html: state.data.custom.address }}></p>
            </div>
            <div className="studio__content__what_we_do">
              <h2 className="">General Inquiries:</h2>
              <a href={`mailto:${state.data.custom.general_inquiries}`}>{state.data.custom.general_inquiries}</a>
              <h2 className="">Collection</h2>
              <a href={`mailto:${state.data.custom.collection}`}>{state.data.custom.collection}</a>
              <h2 className="">Press</h2>
              <a href={`mailto:${state.data.custom.press}`}>{state.data.custom.press}</a>
            </div>
          </motion.div>
          <motion.a
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="studio__booking_btn"
            href="mailto:admin@curacion.co.za"
          >
            Book a Consultation
          </motion.a>
        </>
      }
      </div>
      <Footer />
    </>
  );
}

export default ContactView;
