import { useLayoutEffect, useRef, useState, useEffect } from 'react';
import { InView } from 'react-intersection-observer';

import pinterestIcon from '../../assets/img/pinterest-icon.png';

export default function GalleryItem(props) {
  const ref = useRef();
  const imageRef = useRef();
  const [loaded, setLoaded] = useState(false);

  const [width, setWidth] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);

  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
    }
    window.addEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    props.updateWidth(props.index, width);
  }, [width]);

  useLayoutEffect(() => {
    setWidth(imageRef.current.offsetWidth + parseInt(getComputedStyle(ref.current).marginRight));
    setContainerWidth(imageRef.current.offsetWidth);
  }, [dimensions, loaded]);

  const setActiveIndex = (index) => {
    props.setActiveIndex(index);
  }

  return (
    <li style={{ width: containerWidth }} ref={ref} className="gallery__item">
      <InView as="div" threshold={1} style={{ display: 'block', height: '100%' }} onChange={(inView, entry) => setActiveIndex(props.index)}>
        <img 
          ref={imageRef} 
          onLoad={() => setLoaded(true)} 
          className="gallery__item__image" 
          src={window.innerWidth < 768 ? props.src : props.src_desktop}
          alt={props.alt}
          width="" 
          height="" 
        />
        <a className="gallery__item__pinterest_link" href={`https://pinterest.com/pin-builder/?description=${props.title}&media=${encodeURIComponent(props.src_desktop)}&method=link&url=${encodeURIComponent(window.location.href)}`} target="_blank">
          <img border="0" src={pinterestIcon} title="Pin It" width={30} height={30} />
        </a>
      </InView>
    </li>
  );
}
