import { useEffect, useState } from "react";

import './ProjectsCarousel.scss';

import ProjectsCarouselItem from './ProjectsCarouselItem';

export default function ProjectsCarousel(props) {

  const [state, setState] = useState({ active_slide: 0 });

  useEffect(() => {
    const timer = setInterval(() => {
      if(props.projects.length-1 <= state.active_slide) {
        setState({ active_slide: 0 });
      } else {
        setState({ active_slide: state.active_slide+1 });
      }
    }, 7000);

    return () => {
      clearTimeout(timer);
    };
  }, [state]);

  return (
    <div className="projects_carousel">
      <ul className="projects_carousel__list">
        {props.projects.map((project, index) => {
          return (
            <ProjectsCarouselItem key={index} is_active={state.active_slide == index} name={project.name} image={project.featured_image} />
          );
        })}
      </ul>
    </div>
  );
}
