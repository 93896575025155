import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";

import Header from '../components/HeaderComponent/Header';
import Footer from '../components/FooterComponent/Footer';

function PressView() {
  const { slug } = useParams();

  const [state, setState] = useState({
    loading: true,
    data: {}
  });

  useEffect(() => {
    const endpoint = `${process.env.REACT_APP_API_URL}/wp-admin/admin-post.php?action=get_articles&categories=${slug}`;
    fetch(endpoint)
      .then((res) => res.json())
      .then((data) => {
        setState({ loading: false, data: data });
      });
  }, [setState]);

  if (!state.data.category) return null;

  return (
    <>
      <Helmet>
        {state.data.category.metadata.seo_title ? <title>{state.data.category.metadata.seo_title}</title> : null}
        {state.data.category.metadata.seo_description ? <meta name="description" content={state.data.category.metadata.seo_description} /> : null}
      </Helmet>
      <Header />
      <div className="page press">
        {!state.loading &&
          <>
            <motion.h1
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="page__heading"
            >{state.data.category.name}</motion.h1>
            <motion.ul
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="press__stories"
            >
              {state.data.articles.map((story, index) => {
                return (
                  <li key={index} className="press__stories__story">
                    <a className="press__stories__story__block_link" href={story.link} target="_blank"></a>
                    <div className="press__stories__story__img">
                      <img className="press__stories__story__img__image" src={story.thumbnail} alt="" width="" height="" />
                      <div className="press__stories__story__img__hover"></div>
                    </div>
                    <h2 className="press__stories__story__title">{story.name}</h2>
                    {story.season &&
                      <span className="press__stories__story__date">{story.season}</span>
                    }
                    {!story.season &&
                      <span className="press__stories__story__date">{story.date}</span>
                    }
                  </li>
                )}
              )}
            </motion.ul>
          </>
        }
      </div>
      <Footer />
    </>
  );
}

export default PressView;
