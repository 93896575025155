import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

import './Newsletter.scss';

export default function Newsletter(props) {
  let classNames = 'newsletter';
  if(props.isMinimalDisplay === true) classNames += ' v_minimal';

  const [isFormActive, setIsFormActive] = useState(window.innerWidth >= 1024 || !props.isMinimalDisplay ? true : false);

  function toggleIsFormActive() {
    setIsFormActive(prevIsFormActive => !prevIsFormActive);
  }

  return (
    <div className={classNames}>
      {!isFormActive &&
        <motion.div
          initial={{ y: '100%', opacity: 0 }}
          animate={{ y: '0', opacity: 1 }}
          exit={{ y: '100%', opacity: 0 }}
          transition={{ duration: 0.2, delay: 0.2 }}
        >
          <button className="newsletter__button" onClick={() => toggleIsFormActive()}>Sign up for our Newsletter</button>
          <div className="newsletter__border"></div>
        </motion.div>
      }
      <AnimatePresence>
        {isFormActive &&
          <motion.div
            initial={{ y: '100%' }}
            animate={{ y: '0' }}
            exit={{ y: '100%' }}
            transition={{ duration: 0.2 }}
            className="newsletter__container"
          >
            <button className="newsletter__close_btn" onClick={() => toggleIsFormActive()}></button>
            <h3 className="newsletter__heading">Sign up for our Newsletter</h3>
            <form action="https://curacion.us17.list-manage.com/subscribe/post?u=29b2e6b7eef6620915862015d&amp;id=dda960d3c0&amp;f_id=00b354e0f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="newsletter__form" target="_blank" autoComplete="off">
              <input type="email" name="EMAIL" className="newsletter__form__input" id="mce-EMAIL" placeholder="YOUR EMAIL ADDRESS" />
              <input type="hidden" name="b_2f0c96423d6f7ccb43d6e8d10_680bccb9d2" tabIndex="-1" />
              <input type="submit" value="Sign Up" name="subscribe" id="mc-embedded-subscribe" className="newsletter__form__button" />
            </form>
          </motion.div>
        }
      </AnimatePresence>
    </div>
  );
}
