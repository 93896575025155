import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";

import Header from '../components/HeaderComponent/Header';
import Footer from '../components/FooterComponent/Footer';

function StudioView() {

  const slug = 'studio';

  const [state, setState] = useState({
    loading: true,
    data: {}
  });

  const pageSlug = 'studio';
  const [page, setPage] = useState(null);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_REST_API_URL}/page/${pageSlug}`, {
        method: 'GET',
        mode: 'cors'
    }).then(response => response.json())
        .then(page => setPage(page))
  }, []);

  useEffect(() => {
    const endpoint = `${process.env.REACT_APP_API_URL}/wp-admin/admin-post.php?action=get_page_content&slug=${slug}`;
    fetch(endpoint)
      .then((res) => res.json())
      .then((data) => {
        setState({ loading: false, data: data });
      });
  }, [setState]);

  if (!page) return null;

  return (
    <>
      <Helmet>
        {page.metadata.seo_title ? <title>{page.metadata.seo_title}</title> : null}
        {page.metadata.seo_description ? <meta name="description" content={page.metadata.seo_description} /> : null}
      </Helmet>
      <Header />
      <div className="page studio">
      {!state.loading &&
        <>
          <motion.h1
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="page__heading"
          >{state.data.name}</motion.h1>
          <motion.img
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="studio__image"
            src={state.data.thumbnail}
            alt=""
            width=""
            height=""
          />
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="studio__content"
          >
            {state.data.content && 
              <div className="studio__content__description" dangerouslySetInnerHTML={{ __html: state.data.content }}></div>
            }
            {state.data.custom.what_we_do.length &&
              <div className="studio__content__what_we_do">
                <h2 className="studio__content__what_we_do__heading">What we do:</h2>
                {state.data.custom.what_we_do &&
                    <ul className="studio__content__what_we_do__services">
                      {state.data.custom.what_we_do.map((item, index) => <li key={index} className="studio__content__what_we_do__services__item">{item.name}</li>)}
                    </ul>
                }
              </div>
            }
            {state.data.custom.team_members.length &&
              <div className="studio__content__team">
                <div className="studio__content__team__intro">
                  <h2 className="studio__content__team__intro__heading">Meet<br/> our Team</h2>
                  <p className="studio__content__team__intro__description">{state.data.custom.team_members_intro}</p>
                </div>
                {state.data.custom.team_members &&
                  <div>
                    <ul className="studio__content__team__members">
                      {state.data.custom.team_members.slice(0, 5).map((team_member, index) => 
                        <li key={index} className="studio__content__team__members__item">
                          <img className="studio__content__team__members__item__image" src={team_member.image.url} alt={`${team_member.first_name} ${team_member.last_name}`} width="100" height="100" />
                          <div className="studio__content__team__members__item__info">
                            <h3 className="studio__content__team__members__item__info__name">{team_member.first_name} {team_member.last_name}</h3>
                            <h4 className="studio__content__team__members__item__info__position">{team_member.position}</h4>
                          </div>
                        </li>
                      )}
                    </ul>
                    <div className="studio__content__team__members__divider"></div>
                    <ul className="studio__content__team__members">
                      {state.data.custom.team_members.slice(5, state.data.custom.team_members.length).map((team_member, index) => 
                        <li key={index} className="studio__content__team__members__item">
                          <img className="studio__content__team__members__item__image" src={team_member.image.url} alt={`${team_member.first_name} ${team_member.last_name}`} width="100" height="100" />
                          <div className="studio__content__team__members__item__info">
                            <h3 className="studio__content__team__members__item__info__name">{team_member.first_name} {team_member.last_name}</h3>
                            <h4 className="studio__content__team__members__item__info__position">{team_member.position}</h4>
                          </div>
                        </li>
                      )}
                    </ul>
                  </div>
                }
              </div>
            }
          </motion.div>
          {/*
            <motion.a
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="studio__booking_btn"
              href="mailto:info@curacion.co.za"
            >
              Book a Consultation
            </motion.a>
          */}
        </>
      }
      </div>
      <Footer />
    </>
  );
}

export default StudioView;
