import { useEffect } from "react"
import { useLocation } from "react-router-dom"

function gtag() {
  window.dataLayer.push(arguments)
}

const usePageTracking = () => {
  const location = useLocation()

  useEffect(() => {
    setTimeout(() => {
      window.dataLayer = window.dataLayer || []
      gtag('js', new Date())
      gtag('config', process.env.REACT_APP_GOOGLE_ANALYTICS_ID, {
        'page_title' : document.title,
      })
      // gtag('config', process.env.REACT_APP_GOOGLE_ANALYTICS_ID)
    }, 2000)
  }, [location])
}

export default usePageTracking