import { Route, Routes, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import usePageTracking from "./hooks/usePageTracking"

import './App.scss';

import HomeView from "./views/Home";
import InteriorsView from "./views/Interiors";
import CollectionView from "./views/Collection";
import ProductView from "./views/Product";
import StyledView from "./views/Styled";
import ArchitectureView from "./views/Architecture";
import PropertiesView from "./views/Properties";
import ForSaleView from "./views/ForSale";
import ProjectView from "./views/Project";
import StudioView from "./views/Studio";
import PressCategoriesView from "./views/PressCategories";
import PressView from "./views/Press";
import ContactView from "./views/Contact";

function App() {
  const location = useLocation();
  usePageTracking()

  return (
    <AnimatePresence exitBeforeEnter>
      <Routes key={location.pathname} location={location}>
        <Route path="/" element={<HomeView />} />
        <Route path="/interiors" element={<InteriorsView />} />
        <Route path="/collection" element={<CollectionView />} />
        <Route path="/product/:slug" element={<ProductView />} />
        <Route path="/styled" element={<StyledView />} />
        <Route path="/architecture" element={<ArchitectureView />} />
        <Route path="/properties" element={<PropertiesView />} />
        <Route path="/for-sale" element={<ForSaleView />} />
        <Route path="/projects/:slug" element={<ProjectView />} />
        <Route path="/studio" element={<StudioView />} />
        <Route path="/press" element={<PressCategoriesView />} />
        <Route path="/press/:slug" element={<PressView />} />
        <Route path="/contact" element={<ContactView />} />
      </Routes>
    </AnimatePresence>
  );
}

export default App;
