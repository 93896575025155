import { useState, useEffect } from "react";
import { motion } from "framer-motion";

import './ProductsIndex.scss';

import Filter from '../FilterComponent/Filter';
import ProductsIndexItem from './ProductsIndexItem';

export default function ProductsIndex(props) {

  const [dataState, setDataState] = useState({
    loading: true,
    data: [],
    active_filter: null
  });

  useEffect(() => {
    filterProducts();
  }, [setDataState]);

  function hasFilters() {
    return props.hasOwnProperty('filters') && props.filters.length;
  }

  function getProductsEndpoint(id = null) {
    let endpoint = `${process.env.REACT_APP_API_URL}/wp-admin/admin-post.php?action=get_products`;
    if(id) {
      endpoint += '&categories='+id
    }
    return endpoint;
  }

  function filterProducts(id = null) {
    fetch(getProductsEndpoint(id))
      .then((res) => res.json())
      .then((data) => {
        setDataState({ loading: false, data: data, active_filter: id });
      });
  }

  return (
    <div className="products_index">
      {/*
        {hasFilters() &&
          <Filter filters={props.filters} filterProducts={filterProducts} active_filter={dataState.active_filter} />
        }
      */}
      <h2 id="index" className="products_index__title">{props.title}</h2>
      {dataState.data.length &&
        <ul className="products_index__listing">
          {dataState.data.map((project, index) => (
            <ProductsIndexItem key={index} slug={project.slug} name={project.name} shop_link={project.shop_link} image={project.thumbnail} />
          ))}
        </ul>
      }
    </div>
  );
}
